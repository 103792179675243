<template>
  <section class="header-multi container-fluid" >
    <transition name="bannerMulti">
      <b-row fluid v-for="item in $props.items" :key="item.id">
        <b-col class="d-none d-md-block d-lg-block bg-white" md="5" :style="'height:25rem;background-image:url('+item.image+');background-size:cover;background-position:left 0;'">
            <!-- NOTHING FOR NOW -->
        </b-col>
        <b-col md="7" class="text-left bg-light">
          <div itemscope :itemtype="'https://schema.org/'+schema">
            <h1 class="title pt-2 pl-2 pr-2" itemprop="name" v-html="item.title+'<span class=\'dline_lg\'></span>'"></h1>
            <h2 class="subtitle pl-2 pb-2 mb-4" v-html="item.subtitle" itemprop="keywords"></h2>
              <div style="display:inline;margin-right:0.5rem;" class="pl-2 text-left" v-for="button in item.buttons" :key="button.id">
                <ButtonMeetingComponent class="pb-2 pt-2 lead"  v-if="button.type=='meeting'" :text="button.text" :variant="button.variant" />
                <ButtonComponent v-else class="pb-2 pt-2 lead" :text="button.text" :icon="button.icon" :variant="button.variant" :href="button.link"></ButtonComponent>
              </div>
            <div class="pt-4"><label class="note" v-html="item.note"></label></div>
          </div>
        </b-col>
      </b-row>
    </transition>
  </section>
</template>

<script>
import ButtonComponent from '../buttons/ButtonComponent.vue';
import ButtonMeetingComponent from "../buttons/ButtonMeetingComponent.vue";
export default {
  name: 'BannerMultiProductComponent',
  components: { ButtonMeetingComponent, ButtonComponent },
  props: {
    items: Array,
    schema: String
  },
  data() {
    return {
      item_id:1
    }
  },
  methods: {
    transition(){

    }
  },
  mounted() {
    this.transition()
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/basic.scss";
  .header-multi{
    min-height:25rem;
    height:auto;
    background:#fefefe;
    border-bottom: 6px solid #FF8028;
    .title{
      margin-top:2rem;
      font-size:3rem;
      line-height:3.5rem;
      font-weight: bold;
      color:#0b1c98;
    }
    .subtitle{
      font-size:1.8rem;
      line-height:2rem;
      color: #777777;
      width: 90%;
    }
    .note{
      font-size:1rem;
      color:#777777;
      font-family:$font-quicksand;
      margin-left:1rem;
    }
    @media screen and (max-width: 768px) {
      .title {
        font-size: 2.5rem;
        line-height: 2.7rem;
        font-weight: bold;
        color: #0b1c98;
      }
      .subtitle {
        font-size: 1.5rem;
        color: #777777;
        width: 100%;
      }
    }
    @media screen and (max-width: 1280px) {
      .title {
        font-size: 2.3rem;
        line-height: 2.5rem;
        font-weight: bold;
        color: #0b1c98;
      }
      .subtitle {
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #777777;
        width: 100%;
      }
      .note {
        font-size: 0.8rem;
        color: #777777;
        font-family: $font-quicksand;
        margin-left: 1rem;
      }
    }
  }
</style>
